import vuexStore from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import { initMonitoring } from '@/frontend-monitoring'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { getCookie } from 'tiny-cookie'
import { toMiliseconds } from '@/utils/promise-util'
import isElectron from 'is-electron'
import pages from './routes/pages'
import security from './routes/security'
import catalog from './routes/catalog'
import invoice from './routes/invoice'
import store from './routes/store'
import sale from './routes/sale'
import purchase from './routes/purchase'
import payBox from './routes/pay-box'
import financial from './routes/financial'
import stock from './routes/stock'
import settings from './routes/settings'
import kiosk from './routes/kiosk'
import reports from './routes/reports'
import support from './routes/support'
import bar from './routes/bar'
import requests from './routes/requests'

Vue.use(VueRouter)

const homeRoute = {
  path: '',
  name: 'home',
  component: () => import('@/views/Home.vue'),
  meta: {
    pageTitle: 'Home',
    resource: 'Auth',
    action: 'read',
    breadcrumb: [
      {
        text: 'Home',
        active: true,
      },
    ],
  },
}

const intervals = {}

const autoRefreshRoutes = {
  'beer-tap-list': {
    callback: () => vuexStore.dispatch('pages/kiosk/beerTaps/fetchBeerTaps'),
    interval: toMiliseconds({ min: 1 }),
    isValid: () => true,
  },
  'bar-pending-request-closing': {
    callback: () => vuexStore.dispatch('pages/pdv/payBoxBar/fetchClosingRequestPendingPrint'),
    interval: toMiliseconds({ sec: 30 }),
    isValid: () => vuexStore.getters['pages/pdv/payBoxBar/canInitClosingRequestPrint'],
  },
  'bar-pending-partial-consumption': {
    callback: () => vuexStore.dispatch('pages/pdv/payBoxBar/fetchPartialConsumptionPendingPrint'),
    interval: toMiliseconds({ sec: 20 }),
    isValid: () => vuexStore.getters['pages/pdv/payBoxBar/canInitPartialConsumptionPendingPrint'],
  },
}

const initRefreshRoutes = routeName => {
  const defaultIntervals = ['bar-pending-request-closing', 'bar-pending-partial-consumption']

  defaultIntervals.forEach(data => {
    const isValid = autoRefreshRoutes[data]?.isValid()

    if (!intervals[data] && isValid) {
      intervals[data] = setInterval(
        () => autoRefreshRoutes[data].callback(),
        autoRefreshRoutes[data].interval
      )
    } else if (intervals[data] && !isValid) {
      clearInterval(intervals[data])
      intervals[data] = null
    }
  })

  if (autoRefreshRoutes[routeName]) {
    intervals[routeName] = setInterval(
      () => autoRefreshRoutes[routeName].callback(),
      autoRefreshRoutes[routeName].interval
    )
  }

  const isInDefault = refreshRouteName => defaultIntervals.some(d => refreshRouteName === d)

  Object.keys(intervals)
    .filter(refreshRouteName => refreshRouteName !== routeName && !isInDefault(refreshRouteName))
    .forEach(refreshRouteName => {
      clearInterval(intervals[refreshRouteName])
      intervals[refreshRouteName] = null
    })
}

const routes = [
  homeRoute,
  {
    ...homeRoute, // electron starts the app on `index.html` (without this when user open the app loggedin, can not access the route)
    path: '/index.html',
    name: 'home-index-html',
  },
  ...pages,
  ...store,
  ...security,
  ...catalog,
  ...invoice,
  ...sale,
  ...purchase,
  ...payBox,
  ...financial,
  ...stock,
  ...settings,
  ...bar,
  ...kiosk,
  ...reports,
  ...support,
  ...requests,
]

if (isElectron()) {
  routes.push({
    path: '/electron/splash-screen',
    name: 'electron-splash-screen',
    component: () => import('@/views/ElectronSplashScreen.vue'),
    meta: {
      layout: 'full',
    },
  })
}

const router = new VueRouter({
  mode: 'history',
  base: '/',
  scrollBehavior(to, from) {
    if (to.hash) {
      return {
        selector: to.hash,
      }
    }
    // Skip if destination full path has query parameters and differs in no other way from previous
    if (from && Object.keys(to.query).length) {
      if (to.fullPath.split('?')[0] === from.fullPath.split('?')[0]) {
        return undefined
      }
    }
    return { x: 0, y: 0 }
  },
  routes,
})

router.beforeEach(async (to, _, next) => {
  if (to.query && to.query['go-to-route']) {
    return next({ path: to.query['go-to-route'] })
  }

  initRefreshRoutes(to.name)
  await initMonitoring({})

  if (to.query && to.query.logout) {
    // Remove userData from localStorage
    // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
    useJwt.logout()

    // Reset ability
    this.$ability.update(initialAbility)
  }
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'block'
  }
  // debugger
  const isLoggedIn = isUserLoggedIn()
  // console.log(await canNavigate(to))
  const byPassRoutes = ['auth-login', 'misc-not-authorized', 'electron-splash-screen']
  await canNavigate(to)
  const canNavigateTo = await canNavigate(to)
  // debugger
  if ((!canNavigateTo || !isLoggedIn) && byPassRoutes.indexOf(to.name) < 0) {
    // debugger
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    /* eslint-disable no-console */
    console.log(
      'misc-not-authorized',
      `isLoggedIn: ${isLoggedIn}`,
      `canNavigateTo: ${canNavigateTo}`,
      to
    )
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

router.afterEach((to, from) => {
  if (to.fullPath.indexOf('op=') === -1 && to.path === 'login') {
    router.replace({
      ...to,
      query: {
        ...to.query,
        op: from.query.op || getCookie('tenant'),
      },
    })
  }
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
