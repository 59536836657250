import { mapActions, mapMutations, mapGetters } from 'vuex'
import store from '@/store'
import { isPdvRoute } from '@/router/utils'
import { isUserAllowedStore } from '@/auth/utils'
import isElectron from 'is-electron'
import {
  PayBoxMissingSetupError,
  PayBoxInativeError,
  PayBoxUnknownError,
} from '@/store/pages/pdv/pay-box-configuration'

const environment = window.env || 'local'
const isProd = environment === 'production'
const isElectronLocal = isElectron()

const payboxConfiguration = {
  data() {
    return {
      macAddressWorkstation: null,
    }
  },
  computed: {
    ...mapGetters('pages/pdv/payBoxConfiguration', [
      'currentPayboxConfiguration',
      'tefAgentConfigPayload',
    ]),
  },
  methods: {
    ...mapActions('pages/pdv/payBoxConfiguration', [
      'fetchPayBox',
      'fetchPayBoxByMacAddress',
      'checkPayBoxIsActiveByMacAddress',
      'checkCurrentPayBoxConfigAndFetch',
    ]),
    ...mapActions('pages/pdv', ['loadPdvData']),
    ...mapMutations('pages/pdv/payBoxConfiguration', ['SET_PAY_BOX_ID']),

    ...mapActions('pages/pdv/payBoxConfiguration', {
      stPayboxConfigurationCleanState: 'cleanState',
    }),
    ...mapActions('pages/pdv/payBox', {
      stPayboxCleanState: 'cleanState',
    }),

    async pageLoadPdv(showLoading) {
      try {
        if (showLoading) {
          this.showLoadingOverlay()
        }

        await this.checkCurrentPayBoxConfigAndFetch()

        if (this.$router.currentRoute.name !== 'pdv-pay-box-configuration') {
          await this.loadPdvData()
        }
      } catch (error) {
        if (error?.response?.status === 404) {
          if (this.$router.currentRoute.name !== 'pdv-pay-box-open') {
            this.$router.push({ name: 'pdv-pay-box-open' })
          }
        } else {
          this.showError({ error })
          this.$router.push({ name: 'home' })
        }
      } finally {
        if (showLoading) {
          this.hideLoadingOverlay()
        }
      }
    },
    async loadPdvCurrentConfiguration() {
      if (!isProd) {
        const usePayBoxId = Number(localStorage.getItem('devOnlyUsePayboxId'))
        if (usePayBoxId) {
          console.warn(`Force using use-paybox-id: ${usePayBoxId}`)

          const currentId = Number(this.currentPayboxConfiguration.id)
          if (usePayBoxId !== currentId) {
            this.stPayboxConfigurationCleanState()
            this.stPayboxCleanState()
          }

          this.SET_PAY_BOX_ID(usePayBoxId)
          await this.fetchPayBox()
          return
        }
      }

      if (!isElectronLocal) {
        return
      }
      const payboxData = await window.electronAPI.system.identifyPayboxRead()
      const systemInfo = await window.electronAPI.system.systemInfo()
      await this.fetchPayBoxByMacAddress({
        guid: payboxData?.guid,
        macAddress: systemInfo.macAddress,
      })
    },

    warmClients() {
      if (isElectronLocal && this.tefAgentConfigPayload.enabled) {
        window.electronAPI.tef
          .initialize({
            ...this.tefAgentConfigPayload,
            warmAction: true,
            restartClientMode: 'OnError',
          })
          .catch(err => console.error('Error warming clients', err))
      }
    },
    async checkUserStorePermission() {
      const payBoxPdvFilterStoreId =
        store.state.pages?.pdv?.payBoxConfiguration?.pdvGlobalFilters?.payBox?.store?.id

      if (payBoxPdvFilterStoreId && isPdvRoute() && !isUserAllowedStore(payBoxPdvFilterStoreId)) {
        this.showError({
          title: this.$t('Configuração PDV'),
          message: this.$t(
            'Usuário não tem acesso para loja configurada para esta estação de trabalho. Entre em contato com o suporte.'
          ),
        })
        this.$router.push({ name: 'pdv-pay-box-unknown-error' })
      }
    },
    async checkPdvCurrentConfiguration() {
      if (!isElectronLocal) {
        return
      }

      try {
        // TODO: Devemos verificar se o TEF/SAT/NFCe foram `skipped`
        // caso a configuração foi realizada primeiro no ERP e depois aberto o PDV
        // todo
        const payboxData = await window.electronAPI.system.identifyPayboxRead()
        await this.checkPayBoxIsActiveByMacAddress(payboxData?.guid, this.macAddressWorkstation)
      } catch (error) {
        if (error instanceof PayBoxMissingSetupError) {
          if (this.$router.currentRoute.name !== 'pdv-pay-box-configuration') {
            this.showWarning({
              title: this.$t('Configuração PDV'),
              message: this.$t('PDV deve ser configurado corretamente'),
            })

            this.$router.push({ name: 'pdv-pay-box-configuration' })
          }
        } else if (error instanceof PayBoxInativeError) {
          this.showError({
            title: this.$t('Configuração PDV'),
            message: this.$t('Este PDV está desabilitado. Entre em contato com o suporte.'),
          })
          this.$router.push({ name: 'pdv-pay-box-inactive' })
        } else if (error instanceof PayBoxUnknownError) {
          this.showError({
            message: this.$t(
              'Ocorreu um erro inesperado ao buscar configurações do PDV. Tente novamente.'
            ),
          })
          this.$router.push({ name: 'pdv-pay-box-unknown-error' })
        }
      }
    },
  },
  async mounted() {
    const isPdv = isPdvRoute()
    try {
      if (isPdv) {
        this.showLoadingOverlay()
      }

      await this.loadPdvCurrentConfiguration()

      await this.checkPdvCurrentConfiguration()

      await this.checkUserStorePermission()
      if (isPdv) {
        await this.pageLoadPdv(false)
      }

      this.warmClients()
    } finally {
      if (isPdv) {
        this.hideLoadingOverlay()
      }
    }
  },
}

const pdvBrowserNotAllowedConfiguration = {
  methods: {
    checkPdvOnBrowser() {
      if (isElectronLocal || !isProd) {
        return
      }
      this.showError({
        title: this.$t('Configuração PDV'),
        message: this.$t('Não é possível utilizar o PDV diretamente pelo browser.'),
      })

      console.info('Not Authorized PDV')
      this.$router.push({ name: 'misc-not-authorized' })
    },
  },
  async mounted() {
    this.checkPdvOnBrowser()
  },
}

export { payboxConfiguration as default, pdvBrowserNotAllowedConfiguration }
