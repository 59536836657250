<!-- eslint-disable vue/no-v-html -->
<template>
  <b-modal
    id="modal-authorization"
    :title="getActionName"
    no-close-on-backdrop
    hide-header-close
    hide-footer
    centered
    size="sm"
    @hidden="onHideActions"
  >
    <FormulateForm
      ref="formDiscount"
      name="formDiscount"
      @submit="onConfirm"
    >
      <b-container>
        <b-row
          v-if="errorDescription"
          class="pb-1"
        >
          <b-alert
            show
            variant="dark"
            style="padding: 15px"
            v-html="errorDescription"
          />
        </b-row>

        <b-row align-h="center">
          <b-col
            md="9"
            class="d-flex flex-column"
          >
            <FormulateInput
              id="modalUserInput"
              v-model="form.user"
              name="user"
              class="w-100 required"
              type="text"
              :label="$t('Usuário')"
              validation="required"
            />

            <FormulateInput
              v-model="form.password"
              name="password"
              class="w-100 required"
              type="password"
              :label="$t('Senha')"
              validation="required"
            />
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col class="d-flex justify-content-end">
            <e-button
              class="mr-1"
              :text="$t('Cancelar')"
              :text-shortcuts="['ESC']"
              variant="danger"
              @click="onCancel"
            />
            <e-button
              variant="primary"
              type="submit"
              :busy="busy"
              :text="$t('Confirmar')"
            />
          </b-col>
        </b-row>
      </b-container>
    </FormulateForm>
  </b-modal>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import { getUserData, hasPermissions } from '@/auth/utils'
import { BModal, BContainer, BRow, BCol, BAlert } from 'bootstrap-vue'
import EButton from '@/views/components/EButton.vue'
import { formulateHelper } from '@/mixins'
import { mapGetters } from 'vuex'
import { getApiErrorDataMessage } from '@/utils/error-utils'

export default {
  components: { BModal, BContainer, BRow, BCol, BAlert, EButton },

  mixins: [formulateHelper],

  data() {
    return {
      busy: false,
      isSync: false,
      modalResolve: null,
      modalReject: null,
      localStoreId: null,
      actionName: '',
      delegatePermission: '',
      form: {
        user: '',
        password: '',
      },
      errorDescription: '',
      actionCallback: null,
    }
  },

  computed: {
    ...mapGetters('pages/pdv/payBoxConfiguration', ['currentPayboxConfiguration']),
    getActionName() {
      return this.$t('Autorizar Ação: ') + this.$t(this.actionName)
    },
  },

  methods: {
    async onConfirm() {
      try {
        this.busy = true
        const userData = getUserData()
        const storeId = this.localStoreId || this.currentPayboxConfiguration.store.id

        const { data: delegateUserData } = await useJwt.delegatePermission({
          userName: this.form.user,
          password: this.form.password,
          delegateUserName: userData.userName,
          delegatePermission: this.delegatePermission,
          storeId,
        })
        if (this.actionCallback) await this.actionCallback(delegateUserData)
        if (this.modalResolve) this.modalResolve(delegateUserData)
        this.onHideModal()
      } catch (error) {
        this.showError({
          message: error.response
            ? this.$t(error.response.data)
            : this.$t('Usuário não autorizado para esta ação'),
        })
      } finally {
        this.busy = false
      }
    },

    resetForm() {
      this.form = {
        user: '',
        password: '',
      }
      this.errorDescription = null
      this.localStoreId = null
      this.actionCallback = null
    },

    onCancel() {
      this.onHideModal()
      if (this.isSync) {
        this.showError({ message: 'Autorização cancelada' })
      }
    },

    onHideModal() {
      this.$bvModal.hide('modal-authorization')
    },

    onHideActions() {
      if (this.modalReject) this.modalReject({ message: 'Autorização cancelado' })
      this.isSync = false
    },

    auth(action, storeId) {
      return new Promise((resolve, reject) => {
        if (hasPermissions(action.permission)) {
          resolve()
          return
        }

        this.$bvModal.show('modal-authorization')
        this.actionName = action.name
        this.delegatePermission = action.permission
        this.resetForm()
        if (storeId) this.localStoreId = storeId
        this.onFocusInput()
        this.modalReject = reject
        this.modalResolve = resolve
      })
    },

    /**
     * @param {*} options.confirmCallback (delegatePermission) => {}
     * @param {Boolean} options.withHasPermission
     * @param {*} options.errorData error data get from getApiErrorData method
     */
    authSync(
      action,
      storeId,
      options = { confirmCallback: null, withHasPermission: true, errorData: null }
    ) {
      const { confirmCallback, withHasPermission, errorData } = options

      if (withHasPermission && hasPermissions(action.permission)) {
        return
      }

      this.isSync = true
      this.resetForm()
      this.actionName = action.name
      this.delegatePermission = action.permission
      if (storeId) this.localStoreId = storeId
      if (confirmCallback) this.actionCallback = confirmCallback
      if (errorData) this.errorDescription = getApiErrorDataMessage(errorData)

      this.$bvModal.show('modal-authorization')
      this.onFocusInput()
    },

    onFocusInput() {
      setTimeout(() => {
        this.focusInput('#modalUserInput')
      }, 400)
    },
  },
}
</script>

<style lang="scss" scoped></style>
