import { isTefCard } from '@/store/utils/paybox/paybox-payments'
import { mapGetters } from 'vuex'

const payBoxPayments = {
  computed: {
    ...mapGetters('pages/pdv/payBoxConfiguration', [
      'tefAgentConfigPayload',
      'thermalPrinterAgentConfigPayload',
    ]),
  },
  methods: {
    async cancelTefPayment(receipt) {
      if (isTefCard(receipt)) {
        const { tefPayment } = receipt
        const { nsuGateway } = tefPayment

        const tefPayload = {
          nsu: nsuGateway,
          originalDate: tefPayment.transactionDateTime,
          amount: receipt.value,
        }

        const formattedValue = this.$options.filters.currency(receipt.value)

        this.showLoadingOverlayV2({
          text: this.$t('PAY_BOX_SALE.TEF.START_TEF_PAYMENT_CANCELATION'),
          title: `${this.$t('Cancelamento TEF')} - (NSU: ${nsuGateway} | Valor: ${formattedValue})`,
        })

        try {
          let tefPaymentCancelation
          switch (receipt.paymentMethod.method) {
            case this.paymentTypeEnum.CREDIT_CARD:
              tefPaymentCancelation = await window.electronAPI.tef.creditPaymentCancel(
                this.tefAgentConfigPayload,
                tefPayload
              )
              break
            case this.paymentTypeEnum.DEBIT_CARD:
              tefPaymentCancelation = await window.electronAPI.tef.debitPaymentCancel(
                this.tefAgentConfigPayload,
                tefPayload
              )
              break
            default:
              throw new Error(
                `Payment Method: ${receipt.paymentMethod.method} not implemented yet !`
              )
          }

          try {
            await window.electronAPI.print({
              ...this.thermalPrinterAgentConfigPayload,
              request: {
                openCashDrawer: false,
                printTexts: [
                  {
                    printText: tefPaymentCancelation.couponStore,
                    template: 'PAYMENT_GETNET',
                  },
                  {
                    printText: tefPaymentCancelation.couponCustomer,
                    template: 'PAYMENT_GETNET',
                  },
                ],
              },
            })
          } catch (error) {
            console.error('error on print tef', error)
          }

          this.hideLoadingOverlay()
          return tefPaymentCancelation
        } catch (error) {
          if (error.canceled) {
            this.showWarning({
              title: this.$t('Pagamento TEF'),
              message: this.$t(error.displayMessage || 'Operação cancelada no equipamento PINPAD'),
            })
          } else {
            /* eslint-disable no-console */
            console.error('tef-error', error)
            this.showError({ error })
          }
          this.hideLoadingOverlay()
          return null
        }
      }

      return null
    },
  },
}

export default payBoxPayments
