const recordTypeEnum = {
  AUTO: 'Auto',
  MANUAL: 'Manual',
  SPREADSHEET: 'Spreadsheet',
}

const statusTypes = {
  data() {
    return {
      recordTypeEnum,
    }
  },

  computed: {
    saleStatusEnum() {
      return {
        IN_PROGRESS: 'InProgress',
        COMPLETED: 'Completed',
        CANCELED: 'Canceled',
        WAITING_PAYMENT: 'WaitingPayment',
        PAYMENT_NOT_MADE: 'PaymentNotMade',
      }
    },
    saleStatusLabel() {
      return {
        InProgress: this.$t('Em andamento'),
        Canceled: this.$t('Cancelado'),
        Completed: this.$t('Finalizado'),
      }
    },

    deliveryStatusEnum() {
      return {
        PENDING: 'Pending',
        PREPARING: 'Preparing',
        IN_PROGRESS: 'InProgress',
        COMPLETED: 'Completed',
        CANCELED: 'Canceled',
      }
    },
    deliveryStatusLabel() {
      return {
        Pending: this.$t('Pendente'),
        Preparing: this.$t('Preparando'),
        InProgress: this.$t('Entregando'),
        Completed: this.$t('Entregue'),
        Canceled: this.$t('Cancelado'),
        null: '-',
      }
    },

    recordTypeLabel() {
      return {
        [recordTypeEnum.AUTO]: this.$t('Auto'),
        [recordTypeEnum.MANUAL]: this.$t('Manual'),
        [recordTypeEnum.SPREADSHEET]: this.$t('Planilha'),
      }
    },
  },

  methods: {
    activeInactiveTypes() {
      return [
        { value: 'true', label: this.$t('Ativo'), classStyle: 'success' },
        { value: 'false', label: this.$t('Inativo'), classStyle: 'danger' },
      ]
    },

    yesNoTypes() {
      return [
        { value: 'true', label: this.$t('Sim'), classStyle: 'success' },
        { value: 'false', label: this.$t('Não'), classStyle: 'danger' },
      ]
    },

    saleStatusOptions() {
      return [
        {
          value: 'InProgress',
          label: this.$t('Em andamento'),
          style: { 'background-color': 'var(--secondary)' },
        },
        {
          value: 'WaitingPayment',
          label: this.$t('Aguardando pagamento'),
          style: { 'background-color': 'var(--warning)' },
        },
        {
          value: 'PaymentNotMade',
          label: this.$t('Pagamento não realizado'),
          style: { 'background-color': 'var(--danger)' },
        },
        { value: 'Canceled', label: this.$t('Cancelado'), classStyle: 'danger' },
        {
          value: 'Completed',
          label: this.$t('Finalizado'),
          style: { 'background-color': '#008200' },
        },
      ]
    },

    deliveryStatusOptions() {
      return [
        { value: 'Pending', label: this.$t('Pendente'), style: { 'background-color': '#ffc107' } },
        {
          value: 'Preparing',
          label: this.$t('Preparando'),
          style: { 'background-color': '#b8c2cc' },
        },
        {
          value: 'InProgress',
          label: this.$t('Entregando'),
          style: { 'background-color': '#008200' },
        },
        {
          value: 'Completed',
          label: this.$t('Entregue'),
          style: { 'background-color': '#28c76f' },
        },
        {
          value: 'Canceled',
          label: this.$t('Cancelado'),
          style: { 'background-color': '#ea5455' },
        },
      ]
    },
    recordTypeOptions() {
      return [
        { value: recordTypeEnum.AUTO, label: this.recordTypeLabel[recordTypeEnum.AUTO] },
        { value: recordTypeEnum.MANUAL, label: this.recordTypeLabel[recordTypeEnum.MANUAL] },
        {
          value: recordTypeEnum.SPREADSHEET,
          label: this.recordTypeLabel[recordTypeEnum.SPREADSHEET],
        },
      ]
    },
  },
}

export default statusTypes
