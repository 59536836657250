import { payBoxTypes, paymentTypes } from '@/mixins'
import { roundDecimal } from '@/utils/number-utils'
import axiosInstance from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'
import payBox from './pay-box'
import payBoxPrint from './pay-box-print'
import payBoxTef from './pay-box-tef'
import saleProducts from './sale-products'
import manageSales from './manage-sales'
import statements from './statements'
import payBoxConfiguration from './pay-box-configuration'
import managePayBox from './manage-pay-box'
import reclaimProducts from './reclaim-products'
import payBoxAccountsPayable from './pay-box-accounts-payable'
import saleCreditProducts from './sale-credit-products'
import payBoxBar from './pay-box-bar'

const { payBoxActionsEnum, paymentTypeEnum } = paymentTypes.data()
const { operationTypeEnum, saleOriginEnum } = payBoxTypes.data()

const getInitialState = () => ({
  cashBookDetail: {
    id: null,
    openingBalance: 0,
    openingUser: {
      name: '',
      document: null,
      email: '',
    },
    payBox: {
      id: null,
      store: null,
      number: null,
      description: '',
    },
    openingDate: null,
    summaryCredits: [],
    summaryDebits: [],
  },
  pdvProductInputMethodEnum: payBoxTypes.data().productInputMethodEnum,
  bleedings: [],
  totalCash: 0,
  totalOutputCashAvailable: 0,
  paymentMethodsInPdv: [],
  invoiceLimits: [],
})

export default {
  namespaced: true,

  state: getInitialState(),

  modules: {
    payBox,
    saleProducts,
    saleCreditProducts,
    payBoxPrint,
    payBoxTef,
    statements,
    manageSales,
    managePayBox,
    payBoxConfiguration,
    reclaimProducts,
    payBoxAccountsPayable,
    payBoxBar,
  },

  getters: {
    getPaymentMethodsInPdvOptions(state) {
      return state.paymentMethodsInPdv.map(pm => ({
        ...pm,
        value: pm.id,
        label: pm.name,
      }))
    },

    getInvoiceLimits(state) {
      return state.invoiceLimits
    },

    needCashUp(state, getters, rootState, rootGetters) {
      const { maxCashAmountPayBox } =
        rootGetters['pages/pdv/payBoxConfiguration/currentPayboxConfiguration']
      return state.totalOutputCashAvailable > maxCashAmountPayBox
    },

    summaryCreditsFormatted(state) {
      return state.cashBookDetail.summaryCredits.map(c => ({
        ...c,
        totalTyped: 0,
        operationType: operationTypeEnum.CREDIT,
      }))
    },
    summaryDebitsFormatted(state) {
      return state.cashBookDetail.summaryDebits.map(d => ({
        ...d,
        totalTyped: 0,
        operationType: operationTypeEnum.DEBIT,
      }))
    },
  },

  mutations: {
    SET_CASH_BOOK_DETAILS(state, details) {
      state.cashBookDetail = { ...state.cashBookDetail, ...details }
    },

    SET_BLEEDINGS(state, bleedings) {
      state.bleedings = bleedings
    },

    SET_PAYMENT_METHODS(state, paymentMethods) {
      state.paymentMethodsInPdv = paymentMethods || []
    },

    SET_INVOICE_LIMITS(state, limits) {
      state.invoiceLimits = limits || []
    },
  },

  actions: {
    async fetchCashBookDetail({ commit, rootGetters, dispatch }) {
      const { id: payBoxId } =
        rootGetters['pages/pdv/payBoxConfiguration/currentPayboxConfiguration']

      if (!payBoxId) {
        const { cashBookDetail } = getInitialState()
        commit('SET_CASH_BOOK_DETAILS', cashBookDetail)
        return
      }

      const { data } = await axiosInstance.get(`/api/cash-book/active/pay-box/${payBoxId}`)

      const summaryCredits = data.summaryCredits.filter(
        credit =>
          credit.origin !== saleOriginEnum.OPENING && credit.origin !== saleOriginEnum.CLOSING
      )
      const summaryDebits = [...data.summaryDebits]

      const cashBookDetail = {
        id: data.id || null,
        openingBalance: data.openingBalance || 0,
        openingUser: {
          name: data.openingUser.name || '',
          document: data.openingUser.document || null,
          email: data.openingUser.email || '',
        },
        payBox: {
          id: data.payBox.id || null,
          store: data.payBox.store || null,
          number: data.payBox.number || null,
          description: data.payBox.description || '',
        },
        openingDate: data.openingDate || null,
        summaryCredits: summaryCredits || [],
        summaryDebits: summaryDebits || [],
      }

      commit('SET_CASH_BOOK_DETAILS', cashBookDetail)
      await dispatch('pages/pdv/payBoxConfiguration/setPayBoxDetail', cashBookDetail.payBox, {
        root: true,
      })
    },

    async fetchCashBookDetailById({ commit }, { cashBookId, fetchLogs }) {
      const fetchClosingLogs = fetchLogs || false
      const { data } = await axiosInstance.get(`/api/cash-book/${cashBookId}`, {
        params: { fetchClosingLogs },
      })

      const summaryCredits = data.summaryCredits.filter(
        credit =>
          credit.origin !== saleOriginEnum.OPENING && credit.origin !== saleOriginEnum.CLOSING
      )
      // const summaryDebits = [...data.summaryDebits]

      const cashBookDetail = {
        ...data,
        // id: data.id || null,
        // openingBalance: data.openingBalance || 0,
        // openingUser: {
        //   name: data.openingUser.name || '',
        //   document: data.openingUser.document || null,
        //   email: data.openingUser.email || '',
        // },
        // payBox: {
        //   id: data.payBox.id || null,
        //   store: data.payBox.store || null,
        //   number: data.payBox.number || null,
        //   description: data.payBox.description || '',
        // },
        // openingDate: data.openingDate || null,
        summaryCredits: summaryCredits || [],
        // summaryDebits: summaryDebits || [],
      }

      commit('SET_CASH_BOOK_DETAILS', cashBookDetail)
      // await dispatch('pages/pdv/payBoxConfiguration/setPayBoxDetail', cashBookDetail.payBox, { root: true })
    },

    async fetchBleedings({ commit, rootGetters }) {
      const { id: payBoxId } =
        rootGetters['pages/pdv/payBoxConfiguration/currentPayboxConfiguration']
      const { data } = await axiosInstance.get(`/api/cash-book/bleeding/pay-box/${payBoxId}`, {
        params: { pageSize: 0, pageIndex: 0 },
      })

      const uniqueRefundIds = new Set(
        data.results
          .map(bleeding => bleeding.cashBookTransactionRefundId)
          .filter(id => id !== null)
      )
      const resultsAfterRemoval = data.results.filter(bleeding => !uniqueRefundIds.has(bleeding.id.toString()))
      const unprocessedRefundTransactions = resultsAfterRemoval.filter(bleeding => bleeding.cashBookTransactionRefundId === null)

      commit('SET_BLEEDINGS', unprocessedRefundTransactions || [])
    },

    async openPdv({ rootGetters }, { balance }) {
      const { id: payBoxId } =
        rootGetters['pages/pdv/payBoxConfiguration/currentPayboxConfiguration']

      const { data } = await axiosInstance.post('/api/cash-book', { payBoxId, balance })
      return data
    },

    async closePdv(
      { rootGetters },
      {
        finalPayBoxChange,
        balance,
        summaries,
        observation,
        delegateUserData,
        isForced,
        finalBleeding,
      }
    ) {
      const { id: payBoxId } =
        rootGetters['pages/pdv/payBoxConfiguration/currentPayboxConfiguration']

      const informedSummaries = summaries.map(s => ({
        paymentMethodId: s.paymentMethod.id,
        total: s.totalTyped,
        operationType: s.operationType,
        origin: s.origin,
      }))

      const finalBleedingValue = finalBleeding || 0

      const closingData = {
        payBoxId,
        balance,
        informedSummaries,
        finalPayBoxChangeValue: finalPayBoxChange,
        finalBleedingValue,
      }
      let closeResult
      if (!isForced) {
        const { data } = await axiosInstance.patch('/api/cash-book', closingData)
        closeResult = data
      } else {
        const { data } = await axiosInstance.patch(
          '/api/cash-book/force',
          { ...closingData, observation },
          {
            headers: {
              'delegate-token': delegateUserData?.token,
            },
          }
        )

        closeResult = data
      }

      return closeResult
    },

    async loadPdvData({ dispatch }) {
      // deixar fora do try pra que retorne o erro da busca no endpoint
      await dispatch('fetchCashBookDetail')

      try {
        await dispatch('updateTotalCashInPdv')

        if (await dispatch('pages/pdv/payBox/checkHasLastSession', null, { root: true })) {
          await dispatch('pages/pdv/payBox/restoreIdbLastSession', null, { root: true })
        }

        await dispatch('loadPaymentMethods')
        await dispatch('fetchInvoiceLimits')
      } catch (error) {
        console.error(error)
        useJwt.logout()
        throw new Error(i18n('Erro ao carregar informações do PDV'))
      }
    },

    // FIXME inicio... mover para um store de PdvOperations?
    // sangria do caixa
    async bleedingAction({ dispatch, rootGetters }, { value, observation, paymentMethodId }) {
      const { id: payBoxId } =
        rootGetters['pages/pdv/payBoxConfiguration/currentPayboxConfiguration']

      const bleedingPayload = {
        operationType: operationTypeEnum.DEBIT,
        value,
        observation,
        payBoxId,
        paymentMethodId: paymentMethodId || 1, // TODO mockado paymentMethodId sangria
      }
      const { data } = await axiosInstance.post('/api/cash-book/bleeding', bleedingPayload)
      await dispatch('updateTotalCashInPdv')
      return data
    },

    async bleedingRefundAction(
      { dispatch, rootGetters },
      { delegateUserData, bleedingId, observation }
    ) {
      const { id: payBoxId } =
        rootGetters['pages/pdv/payBoxConfiguration/currentPayboxConfiguration']
      const { data } = await axiosInstance.delete('/api/cash-book/bleeding', {
        data: {
          payBoxId,
          bleedingId,
          observation,
        },
        headers: {
          'delegate-token': delegateUserData?.token,
        },
      })
      await dispatch('updateTotalCashInPdv')

      return data
    },

    // TODO remover método pois não terá reforço
    // realizar operação de Reforço
    async reinforcementAction(states, { value }) {
      const values = [{ type: 'INPUT', value, paymentType: payBoxActionsEnum.REINFORCEMENT }]
      console.log(values)
    },

    async updateTotalCashInPdv({ state, dispatch }) {
      await dispatch('fetchCashBookDetail')
      const { openingBalance, summaryCredits, summaryDebits } = state.cashBookDetail

      const filterOnlyCash = c => c.paymentMethod.method === paymentTypeEnum.CASH
      const sumPayments = (total, pMethod) => total + pMethod.total

      const totalCredit = summaryCredits.filter(filterOnlyCash).reduce(sumPayments, 0)
      const totalDebit = summaryDebits.filter(filterOnlyCash).reduce(sumPayments, 0)

      state.totalCash = roundDecimal(totalCredit + openingBalance - totalDebit)
      state.totalOutputCashAvailable = roundDecimal(totalCredit - totalDebit)
    },

    // FIXME ...fim mover para um store de PdvOperations?

    async loadPaymentMethods({ commit }) {
      try {
        const { data } = await axiosInstance.get(
          '/api/settings/financial/payment-methods/on-pay-box',
          { params: { pageSize: 0, pageIndex: 0 } }
        )
        commit('SET_PAYMENT_METHODS', data.results)
      } catch (error) {
        console.error(error)
        throw error
      }
    },

    async fetchInvoiceLimits({commit}){
      try {
        const { data } = await axiosInstance.get(`/api/invoices/limits`, {
          params: { searchString: document || '' },
        })
        commit('SET_INVOICE_LIMITS', data)
      } catch (error) {
        console.error(error)
        throw error
      }
    },
  },
}
