import axios from '@axios'
import { address, stringUtils, storePagination, sorting, storeDomains } from '@/mixins'
import { days } from '@/utils/dateUtils'
import { localListAddItem, localListDeleteItem } from '@/store/utils'

// const image = 'https://dbechope.s3-sa-east-1.amazonaws.com/images_site/Loja_1_Araraquara.jpeg'

const getOpeningDays = () => {
  const openingDays = {}
  days.forEach(day => {
    openingDays[day] = {
      openingTime: '09:00',
      closingTime: '00:00',
      delivery: true,
      zeroRate: false,
    }
  })
  return openingDays
}
const getInitialState = () => ({
  ...storePagination.state(),
  apiData: {
    telephones: [],
  },
  filters: {
    id: '',
    tradingName: null,
    companyName: null,
    cnpj: null,
    publicPlace: null,
    province: null,
    city: null,
    email: null,
    active: null,
    type: null,
    businessModel: null,
    headId: null,
    directorId: null,
    investors: null,
    region: null,
  },
  stores: [],
  heads: [],
  directors: [],
  investors: [],
  selectedStoreId: null,

  store: {
    id: null,
    active: true,
    activeOnline: false,
    waiterComission: false,
    delivery: true,
    deliveryType: storeDomains.data().storeDeliveryTypesEnum.CITY,
    deliveryOthersCities: false,
    storeDeliveryLocations: [],
    storeWaiterComissions: [],
    tradingName: null,
    companyName: null,
    cnpj: null,
    maxProductItemsSell: null,
    maxOrderValue: null,
    maxScheduleDays: null,
    zeroRateMinValue: null,
    stateRegistration: null,
    stateRegistrationOfTaxSubstitute: null,
    municipalInscription: null,
    taxCode: null,
    taxRegime: null,
    address: address.data().address,
    telephones: [],
    email: null,
    emailDelivery: null,
    shippingFees: {
      shippingFee: null,
      shippingFeePerKilometer: null,
    },
    onlinePaymentConfig: {
      pix: false,
      credit: false,
      cash: false,
      pixLimit: null,
      creditLimit: null,
      cashLimit: null,
      payOnPickup: null,
    },
    onlineChatEnabled: null,
    type: null,
    businessModel: null,
    headId: null,
    directorId: null,
    investors: [],
    imageWide: null,
    imageSquare: null,
    invoiceSerialNumber: null,
    invoiceLastNumber: null,
    digitalCertificateUrl: null,
    digitalCertificatePassword: null,
    openingHours: getOpeningDays(),
    freightValues: {
      deliveryRate: null,
      kilometerRate: null,
    },
    beerCoolerIntegrationStoreId: null,
    maxAccountsPayableValueOnPayBox: null,
  },
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {
    getStoreDeliveryLocations(state) {
      return state.store.storeDeliveryLocations.filter(s => !s.isDeleted)
    },

    getStoreWaiterComissions(state) {
      return state.store.storeWaiterComissions.filter(s => !s.isDeleted)
    },

    imageSquare(state) {
      if (!state.store.imageSquare) return null
      return {
        url: state.store.imageSquare,
        name: state.store.imageSquare,
        type: stringUtils.methods.getFileType(state.store.imageSquare),
        size: 0,
        ext: stringUtils.methods.getFileExtension(state.store.imageSquare),
        urlValue: state.store.imageSquare,
      }
    },
    imageWide(state) {
      if (!state.store.imageWide) return null
      return {
        url: state.store.imageWide,
        name: state.store.imageWide,
        type: stringUtils.methods.getFileType(state.store.imageWide),
        size: 0,
        ext: stringUtils.methods.getFileExtension(state.store.imageWide),
        urlValue: state.store.imageWide,
      }
    },
    digitalCertificate(state) {
      if (!state.store.digitalCertificateUrl) return null
      return {
        url: state.store.digitalCertificateUrl,
        name: state.store.digitalCertificateUrl,
        type: stringUtils.methods.getFileType(state.store.digitalCertificateUrl),
        size: 0,
        ext: stringUtils.methods.getFileExtension(state.store.digitalCertificateUrl),
        urlValue: state.store.digitalCertificateUrl,
      }
    },
    digitalCertificateName(state) {
      return stringUtils.methods.getFileName(state.store.digitalCertificateUrl)
    },
    headOptions(state) {
      return state.heads.map(head => ({
        ...head,
        value: head.id,
        label: head.name,
      }))
    },
    directorOptions(state) {
      return state.directors.map(director => ({
        ...director,
        value: director.id,
        label: director.name,
      }))
    },
    investorsOptions(state) {
      return state.investors.map(investor => ({
        value: investor.id,
        label: investor.name,
      }))
    },
  },

  mutations: {
    ...storePagination.mutations,

    SET_STORE_DELIVERY_LOCATIONS(state, deliveryLocations) {
      state.store.storeDeliveryLocations = deliveryLocations
    },
    SET_STORE_WAITER_COMISSIONS(state, waiterComissions) {
      state.store.storeWaiterComissions = waiterComissions
    },

    SET_API_DATA_TELEPHONES(state, telephones) {
      state.apiData.telephones = telephones
    },
    SET_STORES(state, stores) {
      state.stores = stores
    },
    SET_HEADS(state, heads) {
      state.heads = heads
    },
    SET_DIRECTORS(state, directors) {
      state.directors = directors
    },
    SET_INVESTORS(state, investors) {
      state.investors = investors
    },
    SET_STORE(state, store) {
      state.store = {
        ...store,
        investors: store.investors?.map(investorId => investorId.toString()),
      }
      state.store.imageObject = {
        url: store.image,
        name: store.image,
        type: stringUtils.methods.getFileType(store.image),
        size: 0,
        ext: stringUtils.methods.getFileExtension(store.image),
        urlValue: store.image,
      }
    },
    RESET_FILTERS(state) {
      const { filters } = getInitialState()
      state.filters = filters
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchStores({ commit, state }) {
      const response = await axios.get('/api/stores', {
        params: {
          id: state.filters.id,
          tradingName: state.filters.tradingName,
          companyName: state.filters.companyName,
          cnpj: state.filters.cnpj,
          publicPlace: state.filters.publicPlace,
          province: state.filters.province,
          city: state.filters.city,
          email: state.filters.email,
          active: state.filters.active,
          type: state.filters.type,
          businessModel: state.filters.businessModel,
          headId: state.filters.headId,
          directorId: state.filters.directorId,
          region: state.filters.region,
          sortBy: sorting.methods.getSorting(state),
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
        },
      })
      commit('SET_STORES', response.data.results)
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: response.data.rowCount,
        rowsInCurrentPage: response.data.rowsInCurrentPage,
      })
    },

    async fetchHeads({ commit }) {
      const response = await axios.get('/api/users/heads', {
        params: {
          pageSize: 0,
          sortBy: 'Name ASC',
        },
      })
      commit('SET_HEADS', response.data.results)
    },

    async fetchDirectors({ commit }) {
      const response = await axios.get('/api/users/directors', {
        params: {
          pageSize: 0,
          sortBy: 'Name ASC',
        },
      })
      commit('SET_DIRECTORS', response.data.results)
    },

    async fetchInvestors({ commit }) {
      const response = await axios.get('/api/investors', {
        params: {
          pageSize: 0,
          sortBy: 'Name ASC',
        },
      })
      commit('SET_INVESTORS', response.data.results)
    },

    async fetchStore({ commit }, storeId) {
      const response = await axios.get(`/api/stores/${storeId}`)

      commit('SET_STORE', {
        ...response.data,
        headquartersStoreId: response.data.headquartersStoreId?.toString(),
      })
      commit('SET_API_DATA_TELEPHONES', response.data?.telephones.map(t => ({ ...t })) || [])
    },

    addDeliveryLocation({ state, commit }, deliveryLocation) {
      const list = localListAddItem(state.store.storeDeliveryLocations, deliveryLocation)
      commit('SET_STORE_DELIVERY_LOCATIONS', list)
    },
    removeDeliveryLocation({ state, commit }, idOrLocalId) {
      const list = localListDeleteItem(state.store.storeDeliveryLocations, idOrLocalId)
      commit('SET_STORE_DELIVERY_LOCATIONS', list)
    },

    addWaiterComission({ state, commit }, waiterComission) {
      const list = localListAddItem(state.store.storeWaiterComissions, waiterComission)
      commit('SET_STORE_WAITER_COMISSIONS', list)
    },
    removeWaiterComission({ state, commit }, idOrLocalId) {
      const list = localListDeleteItem(state.store.storeWaiterComissions, idOrLocalId)
      commit('SET_STORE_WAITER_COMISSIONS', list)
    },

    async clearStore({ commit }) {
      commit('SET_STORE', getInitialState().store)
    },

    resetFilters({ commit }) {
      commit('RESET_FILTERS')
    },
  },
}
