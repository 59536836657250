<template>
  <b-button
    v-bind="$attrs"
    :disabled="$attrs.disabled || busy"
    @click="$emit('click', $event)"
  >
    <div
      v-if="busy || icon"
      style="display: inline-block; width: 25px"
    >
      <b-spinner
        v-if="busy"
        small
        style="margin-bottom: 1px"
      />
      <b-icon
        v-if="icon && !busy"
        :icon="icon"
      />
    </div>
    {{ text }}
    <e-shortcut
      v-if="textShortcuts.length > 0"
      :shortcuts="textShortcuts"
      class="d-inline"
    />
  </b-button>
</template>

<script>
import { BButton, BSpinner, BIcon } from 'bootstrap-vue'
import EShortcut from './EShortcut.vue'

export default {
  components: {
    BButton,
    BSpinner,
    BIcon,
    EShortcut,
  },
  props: {
    // context: {
    //   type: Object,
    //   default: null,
    // },
    text: {
      type: String,
      default: null,
    },
    busy: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    textShortcuts: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
