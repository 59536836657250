import { localListAddItem, localListUpdateItem } from '@/store/utils'
import axios from '@axios'

const getInitialState = () => ({
  event: {
    id: null,
    storeId: null,
    priceTableId: null,
    skus: [],
  },
})

export const getInitialItemForm = () => ({
  id: null,
  quantity: 1,
  quantityAdded: null,
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {
    getSkus(state) {
      return state.event.skus.filter(p => !p.isDeleted)
    },
  },

  mutations: {
    SET_EVENT(state, { event }) {
      state.event = event
    },
    SET_EVENT_STORE_ID(state, { storeId }) {
      state.event.storeId = storeId
    },
    SET_EVENT_STORE(state, { store }) {
      state.event.store = store
    },
    SET_SKUS(state, { skus }) {
      state.event.skus = skus
    },
    CLEAN_STATE(state) {
      const { event } = getInitialState()
      state.event = event
    },
  },

  actions: {
    async fetchEventById({ commit }, id) {
      commit('CLEAN_STATE')
      const { data } = await axios.get(`/api/sales/events/${id}`)

      commit('SET_EVENT', {
        event: {
          ...data,
          storeId: String(data.storeId),
          priceTableId: String(data.priceTableId),
          skus: data.skus?.map(sku => ({
            ...sku,
            sku: {
              ...sku.sku,
              // id: String(sku.sku.id),
              value: sku.sku.id,
            },
          })),
        },
      })
    },
    setEventStoreId({ commit }, { storeId }) {
      commit('SET_EVENT_STORE_ID', { storeId })
    },

    async addProduct({ commit, state }, { formData }) {
      const data = {
        ...formData,
      }
      const itemList = localListAddItem(state.event.skus, data)
      commit('SET_SKUS', { skus: itemList })
    },
    updateProduct({ commit, state }, { formData }) {
      const data = {
        ...formData,
        quantity: formData.quantity + (formData.quantityAdded || 0),
      }
      delete data.quantityAdded
      const itemList = localListUpdateItem(state.event.skus, data)
      commit('SET_SKUS', { skus: itemList })
    },
    async removeProduct({ commit, state }, index) {
      // if (!id) throw new Error('Item sem id')
      // const itemList = localListDeleteItem(state.event.skus, id)
      const item = state.event.skus[index]

      if (state.event.id) {
        await axios.delete(`/api/sales/events/${state.event.id}/remove-sku/${item.skuId}`)
      }

      if (item.id) {
        state.event.skus.splice(index, 1)
      } else {
        item.isDeleted = true
      }

      commit('SET_SKUS', { skus: state.event.skus })
    },

    async saveEvent({ state }) {
      await axios({
        url: '/api/sales/events',
        method: state.event.id ? 'PUT' : 'POST',
        data: state.event,
      })
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
