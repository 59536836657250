import { storePagination } from '@/mixins/store'
import axios from '@axios'
import { sorting } from '@/mixins'
import eventMaintain from './event-maintain'

const getInitialState = () => ({
  ...storePagination.state(),
  events: [],
  sorting: {
    sortBy: 'createdDate',
    sortDesc: true,
  },
  filters: {
    eventId: null,
    storeId: null,
  },
})

export default {
  namespaced: true,
  state: getInitialState(),

  modules: { eventMaintain },

  getters: {},

  mutations: {
    ...storePagination.mutations,

    SET_EVENTS(state, events) {
      state.events = events
    },

    SET_FILTERS(state, filters) {
      state.filters = filters
    },

    SET_DEFAULT_CUSTOMER_OPTION(state, customer) {
      if (customer) state.defaultCustomerOptions = [customer]
      else state.defaultCustomerOptions = []
    },

    CLEAN_STATE(state) {
      const { paging, events, filters } = getInitialState()
      state.events = events
      state.paging = paging
      state.filters = filters
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchEvents({ commit, state }) {
      const { eventId, storeId } = state.filters

      const { data } = await axios.get('/api/sales/events', {
        params: {
          eventId,
          storeId,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })

      commit('SET_EVENTS', data.results || [])
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
    },

    resetFilters({ commit }) {
      commit('SET_FILTERS', getInitialState().filters)
    },

    async deleteEvent(_, eventId) {
      await axios({
        url: `/api/sales/events/${eventId}`,
        method: 'DELETE',
      })
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
