<template>
  <input
    v-model="context.model"
    v-mask="getMask()"
    v-bind="context.attributes"
    :input-class="context.attributes.class"
    :data-type="context.type"
    @blur="context.blurHandler"
  >
</template>
<script>
export default {
  name: 'FormulateTextMask',
  props: {
    context: {
      type: Object,
      required: true,
    },
    mask: {
      type: [String, Array],
      require: false,
      default: undefined,
    },
  },
  methods: {
    getMask() {
      if (this.context.attributes.mask) {
        if (this.context.attributes.mask instanceof Array) {
          return [...this.context.attributes.mask]
        }
        return [this.context.attributes.mask]
      }
      return undefined
    },
  },
}
</script>
