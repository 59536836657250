import { sorting } from '@/mixins'
import { storePagination } from '@/mixins/store'
import axios from '@axios'

const getInitialState = () => ({
  ...storePagination.state(),
  payBoxes: [],
  events: [],
  filters: {
    storeId: null,
    active: 'true',
  },
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {
    eventOptions(state) {
      return state.events.map(event => ({
        label: event.name,
        value: event.id,
      }))
    },
  },

  mutations: {
    ...storePagination.mutations,

    SET_PAY_BOXES(state, payBoxes) {
      state.payBoxes = payBoxes
    },
    SET_EVENTS(state, events) {
      state.events = events
    },
    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    CLEAN_STATE(state) {
      const { filters, paging, payBoxes } = getInitialState()
      state.filters = filters
      state.payBoxes = payBoxes
      state.paging = paging
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchPayBoxes({ state, commit }) {
      const { data } = await axios.get('/api/sales/pay-box', {
        params: {
          ...state.filters,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })

      commit('SET_PAY_BOXES', data.results || [])
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
    },

    async fetchPayBoxById(states, id) {
      const { data } = await axios.get(`/api/sales/pay-box/${id}`)
      return {
        ...data,
        nfce: data.nfce ?? {},
        eventId: data.eventId?.toString(),
      }
    },

    async fetchEvents({ commit }) {
      const { data } = await axios.get('/api/sales/events')

      commit('SET_EVENTS', data.results || [])
    },

    resetFilters({ commit }) {
      commit('SET_FILTERS', getInitialState().filters)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
