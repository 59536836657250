import isElectron from 'is-electron'
import axios from '@axios'

const getInitialState = () => {}

export default {
  namespaced: true,
  state: getInitialState(),

  actions: {
    async printBleeding({ rootGetters, dispatch }, { bleedingData, id }) {
      if (!isElectron()) return

      let printPayload = bleedingData
      if (!bleedingData && id) {
        printPayload = await dispatch('pages/pdv/statements/fetchCashBookTransactioById', id, {
          root: true,
        })
      }

      const thermalPrinterAgentConfigPayload =
        rootGetters['pages/pdv/payBoxConfiguration/thermalPrinterAgentConfigPayload']

      try {
        const { macAddress } = await window.electronAPI.system.systemInfo()
        await window.electronAPI.print({
          ...thermalPrinterAgentConfigPayload,
          request: {
            printTexts: [
              {
                template: 'CASHBOOK_BLEEDING',
                printText: JSON.stringify({
                  payBox: {
                    ...printPayload.payBox,
                    macAddress,
                  },
                  datetime: printPayload.date,
                  amount: printPayload.value,
                  user: printPayload.user,
                  observation: printPayload.observation,
                  operationType: printPayload.operationType,
                  paymentMethod: printPayload.paymentMethod,
                }),
              },
            ],
          },
        })
      } catch (error) {
        console.error('error on print bleeding', error)
      }
    },
    async printOpenCashBook({ rootGetters, dispatch }, { openData, id }) {
      if (isElectron()) {
        const thermalPrinterAgentConfigPayload =
          rootGetters['pages/pdv/payBoxConfiguration/thermalPrinterAgentConfigPayload']

        let printPayload = openData
        if (!openData && id) {
          const data = await dispatch('pages/pdv/statements/fetchCashBookTransactioById', id, {
            root: true,
          })

          printPayload = {
            payBox: data.payBox,
            openingDate: data.date,
            openingBalance: data.value,
            openingUser: data.user,
          }
        }

        try {
          const { macAddress } = await window.electronAPI.system.systemInfo()
          await window.electronAPI.print({
            ...thermalPrinterAgentConfigPayload,
            request: {
              printTexts: [
                {
                  template: 'CASHBOOK_OPEN',
                  printText: JSON.stringify({
                    payBox: {
                      ...printPayload.payBox,
                      macAddress,
                    },
                    dateTime: printPayload.openingDate,
                    amount: printPayload.openingBalance,
                    user: printPayload.openingUser,
                  }),
                },
              ],
            },
          })
        } catch (error) {
          console.error('error on print cashbook open', error)
        }
      }
    },
    async printCloseCashBook({ rootGetters }, { closeData, cashBookId, lastForCurrentPaybox }) {
      if (isElectron()) {
        const thermaPrinterConfig =
          rootGetters['pages/pdv/payBoxConfiguration/thermalPrinterAgentConfigPayload']

        let printPayload = closeData

        if (!closeData && cashBookId) {
          const { data } = await axios.get(`/api/cash-book/${cashBookId}`, {
            params: { fetchClosingLogs: false },
          })

          printPayload = data
        } else if (!closeData && lastForCurrentPaybox) {
          const { id: payBoxId } =
            rootGetters['pages/pdv/payBoxConfiguration/currentPayboxConfiguration']
          const { data } = await axios.get(`/api/cash-book/last-closed/pay-box/${payBoxId}`, {
            params: { fetchClosingLogs: false },
          })

          printPayload = data
        }

        const { macAddress } = await window.electronAPI.system.systemInfo()

        try {
          await window.electronAPI.print({
            ...thermaPrinterConfig,
            request: {
              openCashDrawer: true,
              printTexts: [
                {
                  template: 'CASHBOOK_CLOSE',
                  printText: JSON.stringify({
                    ...printPayload,
                    payBox: {
                      ...printPayload.payBox,
                      macAddress,
                    },
                  }),
                },
              ],
            },
          })
        } catch (error) {
          console.error('error on print cashbook close', error)
        }

        try {
          const { data: dataReceivable } = await axios.get(
            `/api/accounting/receivables/cash-book/${printPayload.id}`
          )
          if (dataReceivable) {
            await window.electronAPI.print({
              ...thermaPrinterConfig,
              request: {
                openCashDrawer: false,
                printTexts: [
                  {
                    template: 'CASHBOOK_CLOSE_DIVERGENCE_TERM',
                    printText: JSON.stringify({
                      ...printPayload,
                      payBox: {
                        ...printPayload.payBox,
                        macAddress,
                      },
                    }),
                  },
                ],
              },
            })
          }
        } catch (error) {
          console.error('error on print cashbook divergence term', error)
        }

        try {
          await window.electronAPI.print({
            ...thermaPrinterConfig,
            request: {
              printTexts: [
                {
                  template: 'CASHBOOK_BLEEDING',
                  printText: JSON.stringify({
                    payBox: {
                      ...printPayload.payBox,
                      macAddress,
                    },
                    datetime: printPayload.closingDate,
                    amount: printPayload.finalBleedingValue,
                    user: printPayload.closingUser,
                    observation: 'SANGRIA FINAL',
                    operationType: 'Debit',
                    // TODO: tem algum outro lugar que daria pra reutilizar ?
                    paymentMethod: { id: 1, name: 'Dinheiro', method: 'Cash' },
                  }),
                },
              ],
            },
          })
        } catch (error) {
          console.error('error on print final bleeding', error)
        }
      }
    },
  },
}
