var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-button",
    _vm._b(
      {
        attrs: { disabled: _vm.$attrs.disabled || _vm.busy },
        on: {
          click: function ($event) {
            return _vm.$emit("click", $event)
          },
        },
      },
      "b-button",
      _vm.$attrs,
      false
    ),
    [
      _vm.busy || _vm.icon
        ? _c(
            "div",
            { staticStyle: { display: "inline-block", width: "25px" } },
            [
              _vm.busy
                ? _c("b-spinner", {
                    staticStyle: { "margin-bottom": "1px" },
                    attrs: { small: "" },
                  })
                : _vm._e(),
              _vm.icon && !_vm.busy
                ? _c("b-icon", { attrs: { icon: _vm.icon } })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" " + _vm._s(_vm.text) + " "),
      _vm.textShortcuts.length > 0
        ? _c("e-shortcut", {
            staticClass: "d-inline",
            attrs: { shortcuts: _vm.textShortcuts },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }